<template>
  <v-container>
    <h1 class="text-left multi-title"><span v-html="$t('tsm1_multi_title')" /></h1>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-finalisation ref="search-finalisation" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-processing-grouping ref="search-processing-grouping" @search="search" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        v-if="
          $privileges.has({
            path: '/processingForms/reports/multi/milkLabel',
            permission: 'search'
          })
        "
      >
        <search-label ref="search-label" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-if="$privileges.has({ path: '/dairies', permission: 'search' })">
        <searchDairy ref="search-dairy" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-else-if="$privileges.has({ path: '/dairies', permission: 'searchManaged' })">
        <search-dairy-managed ref="search-dairy-managed" @search="search" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        v-if="
          $privileges.has({
            path: '/processingForms/reports/multi/processingFormTemplate',
            permission: 'search'
          })
        "
      >
        <search-processing-form-templates ref="search-processing-form-templates" @search="search" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        v-if="
          $privileges.has({
            path: '/processingForms/reports/multi/memberOrganisation',
            permission: 'search'
          })
        "
      >
        <search-buyer-organisation ref="search-buyer-organisation" @search="search" />
      </v-col>
      <v-col
        xs="12"
        md="12"
        xl="12"
        v-if="
          $privileges.has({
            path: '/processingForms/reports/multi/processingFormRow',
            permission: 'search'
          })
        "
      >
        <search-processing-row ref="search-processing-row" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('tsm1_multi_search')" :loading="loading" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('tsm1_multi_reset')" /></v-btn>
    <span class="m-4">
      <v-menu offset-y>
        <template v-slot:activator="{ props }">
          <v-btn id="formular" color="secondary" v-bind="props" :loading="downloading">
            <span v-html="$t('tsm1_multi_download')" />
            <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item id="tsm1_multi" key="tsm1_multi" @click="download('multi')">
            <v-list-item-title v-html="$t('tsm1_multi_multi')"></v-list-item-title>
          </v-list-item>
          <v-list-item
            id="tsm1_cheese"
            key="tsm1_cheese"
            @click="download('cheese')"
            v-if="
              $privileges.has({
                path: '/processingForms/reports/multi/download',
                permission: 'cheese'
              })
            "
          >
            <v-list-item-title v-html="$t('tsm1_multi_cheese')"></v-list-item-title>
          </v-list-item>
          <v-list-item
            id="tsm1_cheeseAll"
            key="tsm1_cheeseAll"
            @click="download('cheeseAll')"
            v-if="
              $privileges.has({
                path: '/processingForms/reports/multi/download',
                permission: 'cheeseAll'
              })
            "
          >
            <v-list-item-title v-html="$t('tsm1_multi_cheeseAll')"></v-list-item-title>
          </v-list-item>
          <v-list-item id="tsm1_dairy" key="tsm1_dairy" @click="download('dairy')">
            <v-list-item-title v-html="$t('tsm1_multi_dairy')"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:bottom>
        <!-- no pagination -->
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script lang="ts">
import { emitter, apiURL } from '@/main'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchDairyManaged from '@/components/searchCards/searchDairyManaged.vue'
import { useSearchDairyManagedStore } from '@/store/SearchDairyManagedStore'
import searchFinalisation from '@/components/searchCards/searchFinalisation.vue'
import searchProcessingGrouping from '@/components/searchCards/searchProcessingGrouping.vue'
import searchLabel from '@/components/searchCards/searchLabel.vue'
import searchBuyerOrganisation from '@/components/searchCards/searchBuyerOrganisation.vue'
import searchProcessingFormTemplates from '@/components/searchCards/searchProcessingFormTemplates.vue'
import searchProcessingRow from '@/components/searchCards/searchProcessingRow.vue'
import { Term } from '@/services/term'
import { fileCreator, showError } from '@/services/axios'
import _ from 'lodash'
import { DTSHeader } from '@/services/BackendService'

import { useSearchProcessingFormTemplateStore } from '@/store/SearchProcessingFormTemplateStore'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'
import { useSearchProcessingRowStore } from '@/store/SearchProcessingRowStore'
import { useSearchProcessingGroupingStore } from '@/store/SearchProcessingGroupingStore'
import { useSearchOrganisationStore } from '@/store/SearchOrganisationStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'multi',
  components: {
    searchDairy,
    searchDairyManaged,
    searchFinalisation,
    searchProcessingGrouping,
    searchLabel,
    searchBuyerOrganisation,
    searchProcessingFormTemplates,
    searchProcessingRow
  },
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,
      downloading: false,
      fields: <DTSHeader[]>[
        // Dairy Ident
        {
          title: this.$t('tsm1_multi_dairyIdent'),
          key: 'dairyIdent',
          sortable: false,
          align: 'left'
        },
        // Identifier
        {
          title: this.$t('tsm1_multi_identifier'),
          key: 'identifier',
          sortable: false
        },
        // Produkt Name
        {
          title: this.$t('tsm1_multi_productNameDe'),
          key: 'productNameDe',
          sortable: false
        },
        // Rowtype Name
        {
          title: this.$t('tsm1_multi_rowTypeNameDe'),
          key: 'rowTypeNameDe',
          sortable: false
        }
      ]
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchDairyManagedStore(), useSearchDairyManagedStore().items),
        ...Term.buildTermItems(useSearchProcessingFormTemplateStore(), useSearchProcessingFormTemplateStore().items)
      ]
    },
    params() {
      return {
        processingFormValidUntilSearchRangeMin: useSearchProcessingFormStore().ProcessingForm_validFrom,
        processingFormValidUntilSearchRangeMax: useSearchProcessingFormStore().ProcessingForm_validUntil,
        milkLabel: useSearchProcessingFormStore().ProcessingForm_milkLabel,
        buyerOrganisationId: useSearchOrganisationStore().BuyerOrganisation_id,
        groupByDairy: useSearchProcessingGroupingStore().groupByDairy,
        groupByDate: useSearchProcessingGroupingStore().groupByDate,
        groupByBlock: useSearchProcessingGroupingStore().groupByBlock,
        groupByLabel: useSearchProcessingGroupingStore().groupByLabel,
        codes: useSearchProcessingRowStore().codes
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['dairyIdent,asc'] })
      }
    }
  },
  methods: {
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/processingForms/reports/multi/', {
          params: this.query
        })
        const items = await response.data
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch(() => {
          // route duplicated
          this.load()
        })
    },
    reset() {
      this.$refs['search-finalisation'].reset()
      this.$refs['search-processing-grouping'].reset()
      this.$refs['search-label'].reset()
      if (this.$refs['search-dairy']) this.$refs['search-dairy'].reset()
      if (this.$refs['search-dairy-managed']) this.$refs['search-dairy-managed'].reset()
      if (this.$refs['search-processing-form-templates']) this.$refs['search-processing-form-templates'].reset()
      if (this.$refs['search-buyer-organisation']) this.$refs['search-buyer-organisation'].reset()
      if (this.$refs['search-processing-row']) this.$refs['search-processing-row'].reset()
    },
    async download(report) {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/processingForms/reports/' + report + '/', {
          params: this.query,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        // if loading takes too long
        if ((await response.status) === 204) {
          emitter.emit('openDownloadInfoDialog')
        } else {
          fileCreator(await response, report + '.xlsx')
        }
      } catch (e) {
        const responseData = await e.response.data.text()
        showError({
          response: { data: JSON.parse(responseData), status: 500 }
        })
      } finally {
        this.downloading = false
      }
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.size) this.load()
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.multi-title {
  margin-bottom: $spacer-xs !important;
}

.button-margin {
  margin-right: $spacer-xs !important;
}
</style>
